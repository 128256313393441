import { ALPHA } from '@constants/theme';
import PRODUCTS, { PRODUCT_URL_PREFIX } from '@constants/products';

const body = document.querySelector('body');

export const getAlphaTheme = () => localStorage.getItem('ui-theme') || 'light';

export const isDarkAlphaThemeSupported = (product, pathName = '') => {
  const enableByProduct = [
    PRODUCTS.EI,
    PRODUCTS.AI,
    PRODUCTS.precision,
    PRODUCTS.VDASH,
    PRODUCTS.APP,
    PRODUCTS.ACTIVATION_PREVIEW,
  ].includes(product);
  const enableByPage = pathName.startsWith(PRODUCT_URL_PREFIX[PRODUCTS.deviceValidation]);
  return enableByProduct || enableByPage;
};

export const mergeGlobalState = <T extends Record<string, any>>(state: T) => ({ ...state });

const removeDarkAlphaTheme = () => {
  body!.className = body!.className.replace(ALPHA, '');
};

// temp approach for alpha testing
export const handleDarkAlphaTheme = () => {
  if (body) {
    const alphaTheme = getAlphaTheme();
    const classNames = body.className;
    if (alphaTheme === ALPHA) {
      body.className = classNames.indexOf(ALPHA) === -1 ? `${classNames} ${ALPHA}` : classNames;
    } else {
      removeDarkAlphaTheme();
    }
  }
};

// update/cleanup theme based on product
export const setupDarkAlphaTheme = product => {
  if (isDarkAlphaThemeSupported(product)) {
    // update based on persisted value
    handleDarkAlphaTheme();
  } else {
    // cleanup for other products
    removeDarkAlphaTheme();
  }
};

export const toggleDarkAlphaTheme = () => {
  const currentTheme = getAlphaTheme();
  const newTheme = currentTheme === ALPHA ? 'light' : ALPHA;
  // persist
  localStorage.setItem('ui-theme', newTheme);

  // update global css if we are not on noc dashboard
  if (body && body.className.indexOf('theme-dark') < 0) {
    handleDarkAlphaTheme();
  }

  return newTheme;
};

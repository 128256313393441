function registerHistoryEvent(eventName: 'pushState' | 'replaceState') {
  const origin = history[eventName];
  return function fn(this: History, ...rest: Parameters<typeof history['pushState' | 'replaceState']>) {
    const newEventFn = origin.apply(this, rest);
    const e = new Event(eventName);
    // e.arguments = rest;
    window.dispatchEvent(e);
    return newEventFn;
  }
}

window.history.pushState = registerHistoryEvent('pushState');
window.history.replaceState = registerHistoryEvent('replaceState');

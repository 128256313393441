import { initGlobalState } from 'qiankun';
import { mergeGlobalState } from './theme';

const initialGlobalState = mergeGlobalState({
  landingPages: null,
  user: null,
  nav: null,
  features: {},
  profiles: [],
} as {
  landingPages: Record<string, string> | null;
  user: any;
  nav: any;
  features: Record<string, any>;
  profiles: any[];
});

export type GlobalState = typeof initialGlobalState;

export type OnGlobalStateChange = (state: GlobalState, prev: GlobalState) => void;

const globalStateChangeCallbacks: Set<OnGlobalStateChange> = new Set();

export const addGlobalStateChangeCallback = (onStateChange: OnGlobalStateChange) => {
  if (typeof onStateChange === 'function') {
    globalStateChangeCallbacks.add(onStateChange);
  }
};

export const removeGlobalStateChangeCallback = (onStateChange: OnGlobalStateChange) => {
  if (typeof onStateChange === 'function') {
    globalStateChangeCallbacks.delete(onStateChange);
  }
};

export const createStore = () => {
  const stateActions = initGlobalState(initialGlobalState);

  stateActions.onGlobalStateChange((state, prev) => {
    globalStateChangeCallbacks.forEach(callback => {
      callback(state as GlobalState, prev as GlobalState);
    });
  });

  return stateActions;
};

import { convivaAppTracker } from '@convivainc/conviva-js-appanalytics';
import { PerformanceTimingPlugin } from '@convivainc/conviva-js-appanalytics-performance-timing';
import {
  ErrorTrackingPlugin,
  enableErrorTracking,
} from '@convivainc/conviva-js-appanalytics-error-tracking';
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  enableButtonClickTracking,
} from '@convivainc/conviva-js-appanalytics-click-tracking';

const configENV = process.env.RUNTIME_ENV || 'local';

const prodEnvs = ['production', 'productionbackup'];

const createTrackerConfig = () => {
  if (prodEnvs.includes(configENV)) {
    return {
      appId: 'Pulse5',
      convivaCustomerKey: '1a6d7f0de15335c201e8e9aacbc7a0952f5191d7', // c3.Dryrun
      contexts: {
        performanceTiming: true,
      },
      plugins: [PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()],
    };
  }
  return {
    appId: 'Pulse5',
    convivaCustomerKey: '405a50207754eb87cdce37b228cb52090f95dfb9', // c3.Scaletest
    gatewayUrl: 'http://appgw.qe2.conviva.com',
    contexts: {
      performanceTiming: true,
    },
    plugins: [PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()],
    configs: {
      network: {
        encoding: 'none', // gzip for prod
      },
    },
  };
};

export function initSensor() {
  const trackerConfig = createTrackerConfig();
  convivaAppTracker(trackerConfig);
  enableLinkClickTracking();
  enableButtonClickTracking();
  enableErrorTracking();
}
